import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface LookSlice {
    imageIndex: number
    customIds: string[] | null
}

const initialState: LookSlice = {
    imageIndex: 0,
    customIds: null,
}

// Slice storing data usefull for the look
export const lookSlice = createSlice({
    initialState,
    name: 'look',
    reducers: {
        changeImageIndex: (state: LookSlice, action: PayloadAction<number>) => {
            state.imageIndex = action.payload
        },
        addCustomIds: (state: LookSlice, action: PayloadAction<string[]>) => {
            state.customIds = action.payload
        },
    },
})

// ----- Actions -----
export const { changeImageIndex, addCustomIds } = lookSlice.actions

export default lookSlice.reducer
