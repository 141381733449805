import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RawConfig {
    [key: string]: string
}

export interface ConfigSlice {
    config: {
        [key: string]: string
    } | null
    modal: {
        visible: boolean
    }
}

const initialState: ConfigSlice = {
    config: null,
    modal: {
        visible: false,
    },
}

// Slice storing the config from data attribute
export const configSlice = createSlice({
    initialState,
    name: 'config',
    reducers: {
        addConfig: (state: ConfigSlice, action: PayloadAction<RawConfig>) => {
            state.config = action.payload
        },
        changeModalVisible: (state: ConfigSlice, action: PayloadAction<boolean>) => {
            state.modal.visible = action.payload
        },
    },
})

// ----- Actions -----
export const { addConfig, changeModalVisible } = configSlice.actions

export default configSlice.reducer
