import { ZoomInOutlined } from '@ant-design/icons'
import { Image as AntdImg, Button, Spin } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import LazyLoad from 'react-lazyload'
import { getSyncIcon } from '../utils/icon'
import Lightbox, { Plugin } from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import { MODAL_ROOT_ID } from '../utils/constant'

interface ImageSmoothProps {
    src: string
    ratio: number
    cover?: boolean
    lazyload?: boolean
    transition?: boolean
    loader?: boolean
    loading?: boolean
    className?: string
    overflow?: boolean
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
    enablePreview?: boolean
    fullSizeSrc?: string
    changeImage?(e: React.MouseEvent<HTMLElement>): void
}

const ImageSmooth: React.FunctionComponent<ImageSmoothProps> = (props) => {
    const {
        src,
        ratio,
        cover = false,
        lazyload = true,
        transition = true,
        loader = false,
        loading = false,
        className = '',
        overflow = true,
        onClick,
        enablePreview,
        fullSizeSrc,
        changeImage,
    } = props
    const [currentSrc, setCurrentSrc] = useState<string | null>(null)
    const [currentLoaded, setCurrentLoaded] = useState(false)
    const [oldSrc, setOldSrc] = useState<string | null>(null)
    const [oldOpacity, setOldOpacity] = useState(0)
    const startTimeout: any = useRef()
    const transitionTimeout: any = useRef()
    const [previewVisible, setPreviewVisible] = useState<boolean>(false)

    const transitionTime = 500

    const handleOnLoad = (loadedSrc: string, force = false) => {
        if (loadedSrc === currentSrc || force) {
            setCurrentSrc(loadedSrc)
            setCurrentLoaded(true)
            startTimeout.current = setTimeout(() => {
                setOldOpacity(0)
                transitionTimeout.current = setTimeout(() => setOldSrc(null), transitionTime)
            }, 20)
        }
    }

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        if (onClick) {
            onClick(e)
        }
    }

    const openPreview = (e: React.MouseEvent) => {
        e.stopPropagation()
        setPreviewVisible(true)
    }

    const closePreview = () => {
        setPreviewVisible(false)
    }

    useEffect(() => {
        const customHandleOnLoad = handleOnLoad.bind(this, src)

        clearTimeout(startTimeout.current)
        clearTimeout(transitionTimeout.current)
        ;(window as any).usePreloadImagesData = {}
        const img = new Image()
        if (src !== undefined && src !== null) {
            if (transition) {
                img.src = src as string
                ;(window as any).usePreloadImagesData[src] = img
                if (!img.complete) {
                    //@ts-ignore
                    img.addEventListener('load', customHandleOnLoad)
                    //@ts-ignore
                    img.addEventListener('error', customHandleOnLoad)
                }

                if (currentSrc !== null) {
                    setOldSrc(currentSrc)
                    setOldOpacity(0.99)
                }
                setCurrentSrc(src)
                setCurrentLoaded(false)

                if (img.complete) {
                    handleOnLoad(src, true)
                }
            } else {
                setCurrentSrc(src)
                setCurrentLoaded(true)
            }
        }

        return () => {
            if (src !== undefined && src !== null && transition) {
                clearTimeout(startTimeout.current)
                clearTimeout(transitionTimeout.current)
                //@ts-ignore
                img.removeEventListener('load', customHandleOnLoad)
                //@ts-ignore
                img.removeEventListener('error', customHandleOnLoad)
            }
        }
        // eslint-disable-next-line
    }, [src])

    const isLoading = loading || src !== currentSrc || !currentLoaded

    return (
        <div
            className={`imagesmooth--container ${cover ? `imagesmooth--cover` : ''} ${className}`}
            style={{ paddingBottom: `${ratio}%` }}
            onClick={handleOnClick}
        >
            {currentSrc &&
                (lazyload ? (
                    <LazyLoad height='100%' offset={100} resize={true} overflow={overflow} once>
                        <AntdImg
                            className='imagesmooth--current'
                            src={currentSrc}
                            draggable='false'
                            height='100%'
                            preview={false}
                        />
                    </LazyLoad>
                ) : (
                    <AntdImg
                        className='imagesmooth--current'
                        src={currentSrc}
                        draggable='false'
                        height='100%'
                        preview={false}
                    />
                ))}
            {oldSrc && (
                <AntdImg
                    className='imagesmooth--old'
                    src={oldSrc}
                    draggable='false'
                    height='100%'
                    preview={false}
                    style={Object.assign(
                        {
                            opacity: oldOpacity,
                        },
                        currentLoaded
                            ? {
                                  transition: `opacity ${transitionTime / 1000}s ease-out 0s`,
                              }
                            : {}
                    )}
                />
            )}
            {loader && isLoading && (
                <>
                    <div className='imagesmooth--loader-bg' />
                    <div className='imagesmooth--loader'>
                        <Spin size='large' />
                    </div>
                </>
            )}
            {enablePreview && (
                <div className='card--look-option'>
                    <div className='card--look-option-container'>
                        <Button
                            className='button--icon'
                            icon={<ZoomInOutlined style={{ fontSize: 20 }} />}
                            onClick={openPreview}
                        />
                        {changeImage && (
                            <Button
                                className='button--icon'
                                icon={getSyncIcon()}
                                onClick={changeImage}
                            />
                        )}
                    </div>
                    <Lightbox
                        open={previewVisible}
                        carousel={{ finite: true }}
                        close={closePreview}
                        slides={[{ src: fullSizeSrc || src }]}
                        styles={{ container: { backgroundColor: '#000A' } }}
                        plugins={[Zoom as Plugin]}
                        portal={{ root: document.getElementById(MODAL_ROOT_ID) }}
                        zoom={{
                            maxZoomPixelRatio: 3,
                        }}
                        render={{
                            buttonPrev: () => null,
                            buttonNext: () => null,
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default ImageSmooth
