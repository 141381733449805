import React, { useEffect, useState } from 'react'
import { Look } from '../types/api-types'
import CardFavorites from './CardRecommendation'
import { useAppSelector } from '../services/store/store'
import { GARMENT_TYPES, MODAL_ROOT_ID } from '../utils/constant'
import { calcMaxWidth } from '../utils/calc-max-width'
import Slider from 'react-slick'
import { LeftOutlined, RightOutlined, ZoomInOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { changeImageIndex } from '../services/store/slice/lookSlice'
import { Button, Image } from 'antd'
import { getSyncIcon } from '../utils/icon'
import Lightbox, { Plugin } from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'

const LOOK_RATIO = 0.66

interface LookDetailProps {
    looks: Look[]
    index: number
    onLookChange(newVal: number): void
}

export default function LookDetail(props: LookDetailProps) {
    const { looks, index, onLookChange } = props

    const dispatch = useDispatch()

    const lookIndex = useAppSelector((state) => state.look.imageIndex)

    const [lookDetailImageWidth, setLookDetailImageWidth] = useState<number>()
    const [isZoomVisible, setIsZoomVisible] = useState<boolean>(false)

    const handleChangeImage = (e: React.MouseEvent) => {
        e.stopPropagation()
        dispatch(changeImageIndex(lookIndex === 1 ? 0 : 1))
    }

    const CarouselNextArrow = (arrowProps: any) => {
        const { className, onClick } = arrowProps

        if (!onClick) {
            return null
        }

        const handleClick = (e: React.MouseEvent) => {
            onClick(e)
        }

        return (
            <div
                className={`${className} carousel--look-detail--arrow carousel--right`}
                onClick={handleClick}
            >
                <RightOutlined />
            </div>
        )
    }

    const CarouselPrevArrow = (arrowProps: any) => {
        const { className, onClick } = arrowProps

        if (!onClick) {
            return null
        }

        const handleClick = (e: React.MouseEvent) => {
            onClick(e)
        }

        return (
            <div
                className={`${className} carousel--look-detail--arrow carousel--left`}
                onClick={handleClick}
            >
                <LeftOutlined />
            </div>
        )
    }

    // ---- Setup resize listener for max width of image ----
    useEffect(() => {
        function handleResize() {
            // ---- Max Width calc ----
            const maxWidth = calcMaxWidth(
                window.innerWidth - 32,
                0,
                window.innerHeight - 32,
                LOOK_RATIO,
                0,
                0
            )
            setLookDetailImageWidth(maxWidth)
        }

        handleResize()

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
            dispatch(changeImageIndex(0))
        }
        // eslint-disable-next-line
    }, [])

    // ---- Slick config ----
    const settings = {
        centerMode: true,
        centerPadding: '0px',
        swipeToSlide: true,
        initialSlide: index,
        slidesToShow: 1,
        arrows: true,
        nextArrow: <CarouselNextArrow />,
        prevArrow: <CarouselPrevArrow />,
        touchThreshold: 15,
        afterChange: (newIndex: number) => {
            onLookChange(newIndex)
        },
        beforeChange: () => {
            if (lookIndex !== 0) {
                dispatch(changeImageIndex(0))
            }
        },
    }

    return (
        <div className='look-detail--container'>
            <div
                className='carousel--look-detail--container'
                style={window.innerWidth > 768 ? { width: lookDetailImageWidth } : undefined}
            >
                <Slider {...settings}>
                    {looks.map((look) => (
                        <Image
                            key={look.look_id}
                            src={look.image_urls ? look.image_urls[lookIndex] : ''}
                            draggable='false'
                            preview={false}
                            className='look-detail--look-image'
                            rootClassName='look-detail--look-image-root'
                        />
                    ))}
                </Slider>
                {looks[index] && looks[index].image_urls && (
                    <div className='card--look-option'>
                        <div className='card--look-option-container'>
                            <Button
                                className='button--icon'
                                icon={<ZoomInOutlined style={{ fontSize: 20 }} />}
                                onClick={() => setIsZoomVisible(true)}
                            />
                            {looks[index].image_urls &&
                                (looks[index].image_urls as string[]).length > 1 && (
                                    <Button
                                        className='button--icon'
                                        icon={getSyncIcon()}
                                        onClick={handleChangeImage}
                                    />
                                )}
                        </div>
                        <Lightbox
                            open={isZoomVisible}
                            carousel={{ finite: true }}
                            close={() => setIsZoomVisible(false)}
                            slides={[
                                { src: (looks[index].image_urls as string[])[lookIndex] || '' },
                            ]}
                            styles={{ container: { backgroundColor: '#000A' } }}
                            plugins={[Zoom as Plugin]}
                            portal={{ root: document.getElementById(MODAL_ROOT_ID) }}
                            zoom={{
                                maxZoomPixelRatio: 3,
                            }}
                            render={{
                                buttonPrev: () => null,
                                buttonNext: () => null,
                            }}
                        />
                    </div>
                )}
            </div>
            <div className='look-detail--detail-container'>
                {GARMENT_TYPES.map((type: string) => {
                    if (looks[index][type.toLowerCase()]) {
                        return (
                            <CardFavorites
                                key={type}
                                data={looks[index][type.toLowerCase()]}
                                isOutfit={false}
                                ratio={100}
                                noClick
                            />
                        )
                    }
                })}
            </div>
        </div>
    )
}
