import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import { queryParamSerializer } from '../../utils/query'
import { ROOT_ID } from '../../utils/constant'
export function getCommonHeaders(): { [key: string]: string } {
    return {
        'Content-Type': 'application/json',
        'Accept-Language': 'fr',
    }
}

export const API_ENDPOINT: string = (
    process.env.API_ENDPOINT ||
    process.env.REACT_APP_API_ENDPOINT ||
    ''
).replace('[QUERY_HOSTNAME]', window.location.hostname)

export const getQueryValue = (key: string, defaultValue: string | null = null) => {
    const locationParams = new URLSearchParams(window.location.search)
    const keyValue = locationParams.get(key)
    return keyValue !== null ? keyValue : defaultValue
}

export const getConfigFromAttribubte = () => {
    // ---- We get the root element of our app ----
    const rootElem = document.getElementById(ROOT_ID)

    if (!rootElem) {
        return
    }

    // ---- We get the config attribute ----
    const attributeConfigString = rootElem.getAttribute('data-config')

    if (!attributeConfigString) {
        return
    }

    // ---- We parse it ----
    return JSON.parse(attributeConfigString)
}

const rawConfig = getConfigFromAttribubte()

const apiKey: string | null = rawConfig['api_key']

const apiPrefix: string | null = rawConfig['api_prefix']

export const baseUrl = () =>
    (API_ENDPOINT || `https://api.veesual.ai`) +
    (apiPrefix && !apiPrefix.match(/^\//) ? '/' : '') +
    apiPrefix

// Basic RTK query function to make API calls
export const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl(),
    prepareHeaders: (headers) => {
        headers.set('Content-Type', 'application/json')
        headers.set('Accept-Language', 'fr')
        headers.set('Authorization', 'Bearer ' + apiKey)
        return headers
    },
    headers: getCommonHeaders(),
    paramsSerializer: queryParamSerializer,
})
