import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useAppSelector } from '../services/store/store'
import { useGetLookRecommendationQuery } from '../services/api/api-look'
import ImageSmooth from './ImageSmooth'
import { resizeImage } from '../utils/image'
import { GenericSlide } from 'yet-another-react-lightbox'
import { Button, Modal } from 'antd'
import { Look } from '../types/api-types'
import { CUSTOM_RECOMMENDATIONS_KEY, MODAL_ROOT_ID } from '../utils/constant'
import { t } from 'i18next'
import LookDetail from './LookDetail'
import { getConfigFromAttribubte } from '../services/api/queries'
import CenterSpinner from './CenterSpinner'

declare module 'yet-another-react-lightbox' {
    export interface CustomSlide extends GenericSlide {
        type: 'custom-slide'
        look: Look
    }

    interface SlideTypes {
        'custom-slide': CustomSlide
    }
}

const LOOK_RATIO = 0.66

let checkForDragCardCarousel: number

interface CarouselRecommendationProps {
    customLooks?: Look[]
}

export default function CarouselRecommendation(props: CarouselRecommendationProps) {
    // ---- Props ----
    const { customLooks } = props

    // ---- Hooks ----
    const attributeConfig = getConfigFromAttribubte()

    // ---- Store ----
    const config = useAppSelector((state) => state.config)

    // ---- API ----
    const { data: apiLookRecommendations, isLoading: isRecommendationLoading } =
        useGetLookRecommendationQuery(Object.assign({}, config.config, { nb: 10 }), {
            skip: !!(!config.config || attributeConfig[CUSTOM_RECOMMENDATIONS_KEY]),
        })

    // ---- Choose customLooks if they exist if not we get the apiLooks ----
    const lookRecommendations = customLooks || apiLookRecommendations

    // ---- State ----
    const [lookDetailVisible, setLookDetailVisible] = useState<boolean>(false)
    const [lookIndexActive, setLookIndexActive] = useState<number>(0)

    // ---- Ref ----
    const htmlOverflowRef: any = useRef()

    const closeModal = () => {
        setLookDetailVisible(false)
    }

    const handleSeeDetailClick = (e: React.MouseEvent, index: number) => {
        const mouseUp = e.clientX

        // it's swiping so we ignore
        if (mouseUp > checkForDragCardCarousel + 6 || mouseUp < checkForDragCardCarousel - 6) {
            return
        }

        e.stopPropagation()
        setLookIndexActive(index)
        setLookDetailVisible(true)

        const htmlElement = document.getElementsByTagName('html')[0]
        htmlOverflowRef.current = htmlElement.style.overflowY || null
        htmlElement.style.overflowY = 'hidden'
    }

    const mouseDownCoords = (e: React.MouseEvent<HTMLElement>) => {
        checkForDragCardCarousel = e.clientX
    }

    // ---- Revert the html changes when we close the modal ----
    useEffect(() => {
        if (!lookDetailVisible) {
            const htmlElement = document.getElementsByTagName('html')[0]
            htmlElement.style.overflowY = htmlOverflowRef.current || 'unset'
        }
    }, [lookDetailVisible])

    const CarouselNextArrow = (arrowProps: any) => {
        const { className, onClick } = arrowProps

        if (!onClick) {
            return null
        }

        const handleClick = (e: React.MouseEvent) => {
            onClick(e)
        }

        return (
            <div className={`${className} carousel--arrow carousel--right`} onClick={handleClick}>
                <RightOutlined />
            </div>
        )
    }

    const CarouselPrevArrow = (arrowProps: any) => {
        const { className, onClick } = arrowProps

        if (!onClick) {
            return null
        }

        const handleClick = (e: React.MouseEvent) => {
            onClick(e)
        }

        return (
            <div className={`${className} carousel--arrow carousel--left`} onClick={handleClick}>
                <LeftOutlined />
            </div>
        )
    }

    // ---- Show Spinner if config is not yet in store or recommendation is loading ----
    if (isRecommendationLoading || !config.config) {
        return <CenterSpinner />
    }

    // ---- Show no data message if no lookrecommendations ----
    if (!lookRecommendations || lookRecommendations.length === 0) {
        return <div>{t('no_data')}</div>
    }

    // ---- Slick config ----
    const settings = {
        infinite: false,
        swipeToSlide: true,
        slidesToShow: Math.min(7, lookRecommendations.length),
        nextArrow: <CarouselNextArrow />,
        prevArrow: <CarouselPrevArrow />,
        arrows: true,
        touchThreshold: 15,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: Math.min(2, lookRecommendations.length),
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: Math.min(5, lookRecommendations.length),
                },
            },
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: Math.min(7, lookRecommendations.length),
                },
            },
        ],
    }

    return (
        <>
            <Modal
                className='modal'
                destroyOnClose={true}
                open={lookDetailVisible}
                onCancel={closeModal}
                getContainer={`#${MODAL_ROOT_ID}`}
                width={'100%'}
                centered={true}
                footer={null}
            >
                <LookDetail
                    looks={lookRecommendations}
                    index={lookIndexActive}
                    onLookChange={setLookIndexActive}
                />
            </Modal>
            {lookRecommendations && lookRecommendations.length !== 0 && (
                <div
                    id='veesualLookRecommendationCarouselContainer'
                    className='carousel--container'
                >
                    <Slider {...settings}>
                        {lookRecommendations.map((localLook, index) => (
                            <div
                                key={localLook.look_id}
                                style={{ width: '100%' }}
                                className='card-carousel--container'
                                onMouseDown={mouseDownCoords}
                                onMouseUp={(e) => handleSeeDetailClick(e, index)}
                            >
                                <div className='card--image'>
                                    <ImageSmooth
                                        className='card--background'
                                        src={
                                            localLook.image_urls
                                                ? resizeImage(localLook.image_urls[0], {
                                                      width: 800,
                                                  })
                                                : 'none'
                                        }
                                        ratio={100 / LOOK_RATIO}
                                        loader={true}
                                        lazyload={false}
                                        cover
                                    />
                                </div>
                                <Button className='button--outlined'>{t('see_detail')}</Button>
                            </div>
                        ))}
                    </Slider>
                </div>
            )}
        </>
    )
}
