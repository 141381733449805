export const ROOT_ID = 'veesual_look_recommendation_root'

export const MODAL_ROOT_ID = 'veesual_look_recommendation_modal_root'

// ---- Hardcoded GARMENT_TYPES because we don't have a config from API yet ----
export const GARMENT_TYPES = [
    'TOP',
    'BOTTOM',
    'DRESS',
    'BODY',
    'OUTERWEAR',
    'SHOES',
    'BAG',
    'SCARF',
]

export const CUSTOM_RECOMMENDATIONS_KEY = 'custom_recommendations'
export const CUSTOM_MODELS_KEY = 'custom_models'

// ---- Keys we want to remove from the data-config in the HTML tag to have a clean config store ----
export const REMOVE_CONFIG_KEYS = [
    'api_key',
    'api_prefix',
    'custom_style',
    CUSTOM_RECOMMENDATIONS_KEY,
    CUSTOM_MODELS_KEY,
]
