import React, { useEffect } from 'react'
import { getConfigFromAttribubte } from '../services/api/queries'
import { useDispatch } from 'react-redux'
import { addConfig } from '../services/store/slice/configSlice'
import CarouselRecommendation from '../components/CarouselRecommendation'

import 'yet-another-react-lightbox/styles.css'
import { useGetLooksQuery } from '../services/api/api-look'
import { useAppSelector } from '../services/store/store'
import CenterSpinner from '../components/CenterSpinner'
import {
    CUSTOM_MODELS_KEY,
    CUSTOM_RECOMMENDATIONS_KEY,
    REMOVE_CONFIG_KEYS,
} from '../utils/constant'
import { addCustomIds } from '../services/store/slice/lookSlice'

export default function Main() {
    // ---- Hooks ----
    const dispatch = useDispatch()
    const attributeConfig = getConfigFromAttribubte()

    // ---- Store ----
    const config = useAppSelector((state) => state.config)
    const customLookIds = useAppSelector((state) => state.look.customIds)

    // ---- API ----
    const { data: customLook, isLoading } = useGetLooksQuery(
        { ...config.config, look_id: customLookIds || [] },
        { skip: !!(!config.config || !customLookIds) }
    )

    useEffect(() => {
        // ---- Clone so we don't remove it in the local object used in the query ----
        const cloneAttributeConfig = JSON.parse(JSON.stringify(attributeConfig))

        // ---- We delete fields that we don't need in Query default params ----
        REMOVE_CONFIG_KEYS.forEach((paramKey: string) => {
            delete cloneAttributeConfig[paramKey]
        })

        // ---- We dispatch the config to the store ----
        dispatch(addConfig(cloneAttributeConfig))

        // ---- We check if we have customIds to put in the config ----
        if (attributeConfig[CUSTOM_RECOMMENDATIONS_KEY]) {
            const customLookIdsClone: string[] = JSON.parse(
                JSON.stringify(attributeConfig[CUSTOM_RECOMMENDATIONS_KEY])
            )

            // ---- We check if we have custom models to edit the custom look Ids ----
            if (
                attributeConfig[CUSTOM_MODELS_KEY] &&
                attributeConfig[CUSTOM_MODELS_KEY].length === customLookIdsClone.length
            ) {
                attributeConfig[CUSTOM_MODELS_KEY].forEach(
                    (customModelId: string, index: number) => {
                        const modelIdRegEx = /^(.+?)&/
                        customLookIdsClone[index] = customLookIdsClone[index].replace(
                            modelIdRegEx,
                            customModelId + '&'
                        )
                    }
                )
            }

            dispatch(addCustomIds(customLookIdsClone))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {!config.config || isLoading ? (
                <CenterSpinner />
            ) : (
                <CarouselRecommendation customLooks={customLook} />
            )}
        </>
    )
}
