import { ConfigProvider } from 'antd'
import React from 'react'
import { Provider } from 'react-redux'
import { store } from './services/store/store'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Main from './page/Main'
import './i18n/config'

// ---- Copy of slick css parsed by postcss ----
import './styles/.postcss/slick/slick.css'
import './styles/.postcss/slick/slick-theme.css'
import './styles/.postcss/modal_root_style/slick/slick.css'
import './styles/.postcss/modal_root_style/slick/slick-theme.css'

function App() {
    return (
        <>
            <Provider store={store}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#000',
                            fontFamily: 'inherit',
                        },
                    }}
                >
                    <Main />
                </ConfigProvider>
            </Provider>
        </>
    )
}

export default App
