import { Row, Spin } from 'antd'
import React from 'react'

export default function CenterSpinner() {
    return (
        <Row justify={'center'} align='middle' style={{ width: '100%' }}>
            <Spin />
        </Row>
    )
}
