import React, { useMemo, useRef, useState } from 'react'

import { Button, Card, Col, Typography } from 'antd'
import ImageSmooth from './ImageSmooth'
import { resizeImage } from '../utils/image'
import FilterSelect from './filter'
import parse from 'html-react-parser'
import { formattedPrice } from '../utils/price'
import { useTranslation } from 'react-i18next'

const { Title, Paragraph } = Typography

interface CardFavoritesProps {
    data: any
    isOutfit: boolean
    seeDetailClick?(): void // Additional function called on detail click
    ratio: number
    noClick?: boolean // Disable click on card
    customImageWidth?: number | string
}

const CardFavorites: React.FunctionComponent<CardFavoritesProps> = (props) => {
    const { data, isOutfit, ratio, noClick, seeDetailClick, customImageWidth } = props

    const { t } = useTranslation()

    const [currentSize, setCurrentSize] = useState<string>()
    const sizeSelectRef = useRef<HTMLDivElement>(null)

    const isPromotion = useMemo(() => {
        if (data) {
            return !!data.product_price_original && data.product_price_original > data.product_price
        }

        return false
    }, [data])

    const imageUrl = isOutfit ? data.image_urls[0] : data.image_url

    const handleSizeChange = (value: string) => {
        setCurrentSize(value)
    }

    const handleAddToCart = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (!currentSize) {
            return sizeSelectRef.current?.focus()
        }
    }

    const handleSeeGarmentDetail = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (seeDetailClick) {
            seeDetailClick()
        }
    }

    return (
        <Card
            className={
                'card--container card--container-favorite override_card_container override_card_catalog_container' +
                (!isOutfit && data.product_brand ? ' card--with-brand' : '')
            }
            hoverable={!noClick}
            cover={
                <div className='card--image-container'>
                    <div
                        className='card--image override_card_cover_catalog'
                        style={customImageWidth ? { width: customImageWidth } : {}}
                    >
                        <ImageSmooth
                            className='card--background override_img_container'
                            src={resizeImage(imageUrl, { width: 800 })}
                            ratio={ratio}
                            transition={false}
                            cover={isOutfit}
                            lazyload={false}
                        />
                    </div>
                    {!isOutfit && (
                        <Button
                            className='button--underlined'
                            onClick={handleSeeGarmentDetail}
                            type='link'
                        >
                            {t('see_detail')}
                        </Button>
                    )}
                </div>
            }
        >
            <Col className='card--text-container'>
                {data.product_brand && (
                    <Paragraph
                        ellipsis={{
                            rows: 2,
                        }}
                        className='text text--small card--text override_card_brand'
                    >
                        {parse(data.product_brand)}
                    </Paragraph>
                )}
                <Title
                    ellipsis={{
                        rows: 2,
                    }}
                    className='title title--h3 card--title override_card_title'
                >
                    {parse(data.product_name)}
                </Title>
                {isPromotion && (
                    <Title
                        ellipsis={{
                            rows: 1,
                        }}
                        className='title card--price--promotion'
                    >
                        <span className='card--price--original'>
                            {formattedPrice(data.product_price_original, data.product_currency, 2)}
                        </span>
                        <span className='card--price--percent'>
                            -
                            {Math.round(
                                ((data.product_price_original - data.product_price) * 100) /
                                    data.product_price_original
                            )}
                            %
                        </span>
                    </Title>
                )}
                <Title
                    ellipsis={{
                        rows: 1,
                    }}
                    className={`title title--center title--h2 card--title card--price--final${
                        isPromotion ? ' card--price--final--promotion' : ''
                    }`}
                >
                    {formattedPrice(data.product_price, data.product_currency, 2)}
                </Title>
            </Col>

            <div className='card--favorites-options'>
                <FilterSelect
                    name='size'
                    onChange={handleSizeChange}
                    placeholder={t('choose_size')}
                    translation={false}
                    value={currentSize}
                    options={data.product_sizes}
                    ref={sizeSelectRef}
                />

                <Button className='button card--main' onClick={handleAddToCart} type='primary'>
                    {t('add_to_cart')}
                </Button>
            </div>
        </Card>
    )
}

export default CardFavorites
